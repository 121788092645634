import React, { useEffect } from "react";
import { withRouter } from "react-router";

import Header from "./Header";
import Footer from "./Footer";
import CookieConsentBanner from "./CookieConsentBanner";

const CookiePolicy = (props) => {
    useEffect(() => {
        document.title = `クッキーポリシー | Oudan Sharing`;
    }, []);

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <h2>Cookie ポリシー</h2>
                <div className="texts">
                    <p>　合同会社大人検索（以下、「当社」といいます。）は、お客様のウェブサイト利用状況を分析し、または個々のお客様に対してカスタマイズされたサービスを提供する等の目的のため、Cookieを使用して一定の情報を収集します。</p>

                    <h3>1．Cookieについて</h3>
                    <p>　Cookieとはお客様のウェブサイト閲覧情報を、そのお客様のコンピューター（PCやスマートフォン、タブレットなどインターネット接続可能な機器）に記憶させる機能のことです。</p>
                    <p>　Cookieには、当社によって設定されるもの（ファーストパーティークッキー）と、当社と提携する第三者によって設定されるもの（サードパーティークッキー）があります。本サービスでは、ファーストパーティークッキーのみを利用しています。</p>

                    <h3>2．Cookieの利用目的</h3>
                    <ol>
                        <li>当社では、Cookieを、お客様がウェブサイトを閲覧する際に同じ情報を繰り返し入力することがなくなるなど、お客様の利便性向上のために使用しています。</li>
                        <li>当社では、Cookieを使用して収集した情報を利用して、お客様のウェブサイトの利用状況（アクセス状況、トラフィック、ルーティング等）を分析し、ウェブサイト自体のパフォーマンス改善や、当社からお客様に提供するサービスの向上、改善のために使用することがあります。</li>
                    </ol>


                    <h3>3．Cookieの拒否方法</h3>
                    <ol>
                        <li>
                            全てのCookieを拒否する方法<br />
                            　お客様がブラウザの設定を変更することによりCookieを無効にすることが可能です。ただし、Cookieを無効にした場合は、一部のサービスが受けられない場合があります。
                            Cookieの設定の変更方法については、各ブラウザの製造元へご確認ください。
                        </li>
                    </ol>

                    <p className="tR">以上</p>
                </div>
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    )
};

export default withRouter(CookiePolicy);