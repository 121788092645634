import React, { useEffect } from "react";
import { withRouter } from "react-router";

import Header from "./Header";
import Footer from "./Footer";

import CookieConsentBanner from "./CookieConsentBanner";

const About = (props) => {
    useEffect(() => {
        document.title = `本サービスについて | Oudan Sharing`;
    }, []);

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <h2>本サービスについて</h2>
                <div className="texts">
                    <p>
                        　本サービスは、「令和4年度 つくばSociety5.0社会実装トライアル支援事業」に採択されたプロジェクトの実証実験として
                        合同会社大人検索が運用しているサービスです。
                    </p>
                    <h3>プロジェクトについて</h3>
                    <p className="project-title">
                        グラフデータベースを活用した組織内での<br />
                        情報共有を円滑にするシステム「Oudan Sharing」の実証実験
                    </p>
                    <p>　本提案は、多様な組織内情報の中からメンバーが活用する上で必要な情報を逐一発見し、その情報を社員に応じた最適な形で届けるAIアシスタントに関する技術の実証実験です。</p>
                    <p>　我々は、社内情報の取得を円滑にするシステムを開発し、誰もが専門性を存分に発揮できる社会をつくばから実現します。</p>
                    <br />
                    <p>
                        　詳細は「
                        <a className="page-link" href="https://www.city.tsukuba.lg.jp/shisei/torikumi/kagaku/1017549/1018690.html" target="_blank" rel="noopener noreferrer">
                            【令和4年度実証実験】合同会社大人検索｜つくば市公式ウェブサイト
                        </a>
                        」からご覧ください。
                    </p>
                    <h3>弊社について</h3>
                    <p>
                        　詳細は「
                        <a className="page-link" href="https://otonakensaku.co.jp/" target="_blank" rel="noopener noreferrer">
                            合同会社大人検索
                        </a>
                        」からご覧ください。
                    </p>
                </div>
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    )
};

export default withRouter(About);