import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = (props) => {
    return (
        <CookieConsent
            buttonText="OK"
            cookieName="cookiePoricyConsent"
            disableStyles={true}
            containerClasses="cookie-consent"
            contentClasses="cookie-consent-content"
            buttonWrapperClasses="cookie-consent-button-wrapper"
            buttonClasses="cookie-consent-button"
            expires={150}
        >
            当サイトでは、サイトの利便性向上のためにクッキー(Cookie)を使用しています。<br />
            サイトのクッキー(Cookie)の使用に関しては「
            <Link to="/cookie" target="_blank" rel="noopener noreferrer">
                Cookie ポリシー
            </Link>
            」をお読みください。
        </CookieConsent>
    );
};

export default CookieConsentBanner;