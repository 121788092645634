import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
    return (
        <footer className="footer">
            <div>
                <p className="copyright">©2023 Oudan Sharing. ©2023 Otonakensaku G.K.</p>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/terms">Terms</Link></li>
                    <li><Link to="/privacy">Privacy</Link></li>
                    <li><Link to="/cookie">Cookie</Link></li>
                    <li><Link to="/about">About</Link></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;