import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Amplify } from 'aws-amplify';
import axios from "axios";

import Header from "./Header";
import Footer from "./Footer";
import CookieConsentBanner from "./CookieConsentBanner";

import ConfigAuth from "./Auth/Auth";

Amplify.configure({ Auth: ConfigAuth });
const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const app_domain = process.env.REACT_APP_DOMAIN;

const Home = (props) => {
    const [ warningMessage, setWarningMessage] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ orgName, setOrgName ] = useState("");

    useEffect(() => {
        document.title = `ホーム | Oudan Sharing`;
    }, []);

    const checkDomain = async () => {
        await API.post('/api/check_domain', { 
            'Content-Type': 'application/json',
            'org_name': orgName,
        }).then((res) => {
                let flag = res.data.result;
                if (flag === true) {
                    window.location.href = `https://${orgName}.${app_domain}`;
                } else {
                    setWarningMessage("組織名が間違っています");
                    setLoading(false);
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    const hundleOnClickTransition = (e) => {
        e.preventDefault();
        setLoading(true);
        checkDomain();
    }

    const hundleOnChangeOrgname = (e) => {
        setOrgName(e.target.value);
    }

    const renderButton = () => {
        if (orgName.length > 0 && !loading) {
            return (
                <button className="button" type="submit"
                    onClick={(e) => {hundleOnClickTransition(e)}}>組織ページに移動</button>
            )
        } else {
            return <button className="button disabled-button" type="submit" disabled>組織ページに移動</button>
        }
    }

    return (
        <div className="main">
            <Header />
            <div className="contents">
                <h1>組織 ID を入力してください</h1>
                <form className="login-input-card">
                    <input className="textarea" type="org_id" placeholder="組織 ID を入力" required
                        onChange={(e)=>{
                            hundleOnChangeOrgname(e);
                        }} />
                    <div className="warning-message">
                        <p>{warningMessage}</p>
                    </div>
                    {renderButton()}
                </form>
            </div>
            <CookieConsentBanner />
            <Footer />
        </div>
    )

}

export default withRouter(Home);